import './Nav.css';

function Nav() {
  return (
    <nav>
        <div className="nav-links">
          <a href="/">home</a>
          <a href="/advice">advice</a>
          <a href="/faqs">faqs</a>
          <a href="/submit">submit</a>
        </div>
    </nav>
  );
}

export default Nav;