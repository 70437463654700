import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Nav from './Nav';
import Home from './Home';
import Advice from './Advice';
import FAQs from './FAQs';
import Register from './Register';

import './App.css';

import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <div className="App">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Nav />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/advice" element={<Advice />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/submit" element={<Register />} />
        </Routes>
        <footer>©2024 IMPACT team | website design by <a href="https://hafideledath.com">Hafid Eledath</a></footer>
        <Analytics />
    </BrowserRouter>
    </div>
  );
}

export default App;
