import './Advice.css';

function Advice() {
  return (
    <div className="Advice">
      <h1>Advice</h1>

      <p>Writing poetry may seem difficult; we've all read and analyzed poems in class, but sohmetimes we may think we just don't have the talent to write 
        well. The truth, as clichè as it may sound, is that we all have the ability to write good poetry. What's important is having a passion for
        expression. To begin, think about what you want to write about, and the type of poetry you'll write (for IMPACT, you'll be writing spoken word
        poetry; that means you could focus on things like cadence and meter). Then, try brainstorming a more developed idea, begin forming your poem,
        edit your poem, and finally, deliver it! Here are some general steps that may help:
      </p>

      <div className="steps">
        <div className="step">
          <h4>1. Ideate</h4>
          <p>Begin by thinking about what you want to write about. Think of words related to your idea, and think about what the connotations of those words are. Take time to develop the idea so that it doesn't come off as generic.</p>
        </div>

        <div className="step">
          <h4>2. Form</h4>
          <p>Begin writing down whatever comes to mind; some people like to write in what's known as free verse, which doesn't have a set meter or rhyme scheme, whereas others like to stick to a particular rhyme scheme and/or meter.</p>
        </div>

        <div className="step">
          <h4>3. Edit</h4>
          <p>Edit the work in spaced intervals; first, get rid of grammatical and punctuation errors. Next, work on improving cadence and vocabulary choice. Then, you can give your poem to other people and have them peer review it.</p>
        </div>

        <div className="step">
          <h4>4. Present</h4>
          <p>You've worked hard to craft your poem, now it's time to present! If you have a meter or general rhythm, try and make it evident but not over the top. Make sure that you take notice of your pitch, volume, and emphasis.</p>
        </div>
      </div>

      <p>Remember, these are just loose guidelines, and you should do whatever works best for you. It's easy to write a decent poem, but writing a good one comes down to intricate editing and heavily revised delivery.
        Focusing on niche things like the number of syllables per line or where to place emphasis may seem unnecessary, but can be the few points between a great poem and a winning one. Try reading your poem out loud
        to identify areas with uncomfortable phrasing, and try to amend and redraft your poems as much as possible prior to the slam. All the best!
      </p>
    </div>
  );
}

export default Advice;
