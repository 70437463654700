import './Register.css';

function Register() {
    return (
      <div className="Register">
        <h1>Submit</h1>
        <p>Good luck– check out our <a href="https://www.amazon.com/dp/B0D26VN4TH">previous volume</a> for inspiration!</p>
        <iframe id="registration-form" src="https://forms.office.com/Pages/ResponsePage.aspx?id=AMmUbCCnXEKuE9AmGtoJtKpyDYLlhaBCi7xs7uTBZq5UNFVMTjFTVDYxQTBFWDBOSFdQMjNTODMzUC4u&embed=true" frameborder="0" marginwidth="0" marginheight="0" />
      </div>
    );
  }
  
  export default Register;  