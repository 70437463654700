import './FAQs.css';

function FAQs() {
    return (
      <div className="FAQs">
        <h1>FAQs</h1>
        
        <h3>How many poems can I submit?</h3>
        <p>Every contestant must submit exactly <b>one</b> poem, with a maximum length of 3 minutes.</p>

        <h3>Can my poem be in a language other than English?</h3>
        <p>Yes, but you must provide an English translation for any non-english content for the sake of the judges. Your content will be judged in English, but delivery will be judged in the poem's original language.</p>

        <h3>How old do I need to be to participate?</h3>
        <p>The competition is catered to students between the ages of 12 and 18 at the time of submission, but exceptions can be made for younger students that display outstanding literary ability.</p>

        <h3>How much does it cost to enter?</h3>
        <p>Participation in the competition is free.</p>

        <h3>What do I get if I win?</h3>
        <p>Winners will get gift baskets worth up to 150AED, as well as certificates. The best schools overall will get trophies.</p>

        <h3>Where can I contact you?</h3>
        <p>If you have a question that isn't covered above, contact us via our Instagram, @DIA_IMPACT.</p>
      </div>
    );
  }
  
export default FAQs;